import { Container, ContainerProps } from '@mui/material';
import React from 'react';

import { mergeSx } from '~/utils/mui';
import { useOptionalUser } from '~/utils/user';

const FrontContainer = ({
    deactivateSidePaddings,
    component,
    overrideSignedIn,
    sx,
    ...props
}: ContainerProps & {
    overrideSignedIn?: boolean;
    deactivateSidePaddings?: boolean;
    component?: React.ElementType;
}) => {
    const optionalUser = useOptionalUser();
    return (
        <Container
            component={component || 'div'}
            sx={mergeSx(
                {
                    px: deactivateSidePaddings ? '0px!important' : { xs: 6, sm: 8, md: 12 },
                    ml: optionalUser?.signedIn && !overrideSignedIn ? 0 : 'auto',
                    display: 'flex',
                    flexDirection: 'column',
                },
                sx,
            )}
            {...props}
        />
    );
};

export default FrontContainer;
